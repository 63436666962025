.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html, body {
  overflow-x: hidden;
  background: #101522;
}


.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.imgCar {
  display: none;
  left: 50%;
  position: absolute;
}

.vid {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  
}
.vid2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  display: none;
 
}
@media screen and (max-width: 768px) {
  .vid2{
display: block;
}}

.iframe {  
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  
}

.iframe2 {  
  border-radius: 20px;

  width: 95.5vw;
  height: 50vh;
  
}



.test {
  color: #000000 !important;
}

.test:hover {
  color: #FFFFFF !important;
}

.van {
  animation: float 3s ease-in-out infinite;
  z-index: 2;
  position: relative;
  vertical-align: top;
  float: top;
  margin-bottom: auto;

  top: 3%;
}

.van3 {
  position: absolute;
  animation: float2 3s ease-in-out infinite;
  top: 3%;
  z-index: -1;
  margin-bottom: auto;

  vertical-align: top;
  float: top;
}

.van2 {
  top: -3em;
  position: relative;
  margin-right: auto;
  margin-left: auto;
}



@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-5px);
	}
	100% {
		transform: translatex(0px);
	}
}

.bgimg {
  z-index: 1;
  position: absolute;
  width:100%;
  height:125%;
  object-fit: cover;
  filter: brightness(75%);
  overflow: hidden;
  top: 0em;
  /* filter: blur(5px);
  transform: scale(1.1) */
  
  
}
.logo {
  filter: drop-shadow(2px 2px 2px crimson);
  transition: .3s ease-in-out;

}

.logo:hover {
  color: red;
}

.site_name {
	color: #FFF;
	display: inline-block;
	margin: 0 0 0 20px;
}

.login {
  top: -10em;
  left: 45%;
  position: relative;
}

.first-flex-item{
  background-color: black;
}

.card {
  perspective: 1000px;
  margin: 10px;

}

.card,
.card__inner {
  margin: 10px;
  position: relative; 
  width: 250px;
  height: 300px;
  
  border-radius: 20px;

}

.card__inner {
  transition: transform .75s ease-in-out;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;

}

.card__back {
  transform: rotateY(180deg);

}
.card__front{
  background-color: #990000
}
.card__front,
.card__back {
  flex-direction: column;
  padding: 15px;
  align-items: center;
  position: absolute;
  top: 0;
  transition: .3s ease-in-out;
  left: 0;
  border-radius: 20px;

  display: flex;

  width: 100%;
  height: 100%;

  color: #101522;
  

  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
}
.card__back{
  background-color: #e54e16;
}
.card__front:hover {
  background-color: #e54e16;
}

  .card__inner:hover {
    transform: rotateY(180deg);
    
  }

  .spann {
    z-index: 20;
    color: black;
    display: table-cell;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }

  .spandiv {
   
    display: flex;
  }


  .hover-img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    cursor: pointer;
    }

  
  .hover-img:hover {
    filter: invert(55%) sepia(56%) saturate(5517%) hue-rotate(2deg) brightness(105%) contrast(98%);
  }
  
  .rotate {
    transform: rotate(180deg);
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 600px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.modal-close {
    position: relative;
    left: 99%;
    font-size: 2rem;
    font-weight: bold;
    color: red;
    cursor: pointer;
}


.modal-contact {
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  color: green;
  cursor: pointer;
}
